import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/eps';

export default {
    payments(payload) {
        return axios.get(`${RESOURCE_NAME}/get-eps-payments`, {
            params: {
                date_start: payload.date_start,
                date_end: payload.date_end
            }
        })
    },
    temporary_rows() {
        return axios.get(`${RESOURCE_NAME}/get-temporary-rows`)
    },
    payments_period() {
        return axios.get(`${RESOURCE_NAME}/get-payments-date`)
    },
    create_payments(payload) {
        return axios.post(`${RESOURCE_NAME}/create-payments`, payload)
    },
    delete_payments(payload) {
        return axios.post(`${RESOURCE_NAME}/delete-payments`, payload)
    },
    generate_file() {
        return axios.get(`/generate-file/eps-base-dbf`)
    },
    generate_privat24_file(payload) {
        return axios.post(`/generate-file/privat-base-dbf`, payload, {responseType: 'blob'})
    },
    generate_privat24_file_by_email(payload) {
        return axios.post(`/generate-file/privat-base-dbf-by-email`, payload)
    },
    generate_meter_reading(payload) {
        return axios.post(`/generate-file/meter-reading`, payload, {responseType: 'blob'})
    },
    generate_secrets(left, right) {
        return axios.get(`${RESOURCE_NAME}/get-secrets`, {params: {left_text: left, right_text: right}})
    },
};